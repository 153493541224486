// Bootstrap variables overrides

$primary: #1895d8;
$secondary: #797f8b;
$success: #0bcb1e;
$danger: #bc153c;
$warning: #feb310;
$info: #3fc1d5;
$light: #becacf;
$dark: #020202;

// Bootstrap and its default variables
@import "../node_modules/bootstrap/scss/bootstrap";

// Remove display: inline-block from Datepicker
.react-datepicker-wrapper {
  display: block !important;
}
