@media screen {
  .hide-on-screen {
    display: none;
  }
}

@media print {
  body {
    visibility: hidden;
  }

  .print {
    visibility: visible;
    position: absolute;
  }

  .no-print {
    display: none !important;
  }
}
