li {
  font-size: 1.2rem;
}

.date-picker {
  max-width: 25%;
}

.list-group-item.active {
  z-index: 0 !important;
}
