.font-1-5em {
  font-size: 1.5em;
}

.font-2em {
  font-size: 2em;
}

#total-tool-tip {
  cursor: pointer;
}
