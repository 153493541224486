.ride-list-item {
  cursor: pointer;
  border-radius: 5px;
}

.item-title {
  font-size: 1.25em;
  font-weight: 550;
}

.item-preview {
  font-size: 1.25em;
}

.preview-icon {
  width: 55.9px;
}

#pit-size-icon {
  padding-left: 3px;
}
